<template>
	<div class="layout">
		<a-layout class="container">
			<a-layout-sider class="aside">
				<div class="head" @click="toHome">
					<div>
						<span>idaas admin</span>
					</div>
				</div>
				<div class="line" />
				<a-menu
					id="dddddd"
					@select="toPage"
					v-model:openKeys="openKeys"
					v-model:selectedKeys="selectedKeys"
					style="width: 200 px"
					mode="inline"
					theme="dark"
				>
					<template v-for="(item, index) in menuList" :key="index">
						<a-menu-item v-if="item.children.length === 0" :key="item.view">
							<template #icon>
								<!-- <component v-if="item.icon" v-bind:is="item.icon"></component> -->
							</template>
							<span>{{ item.name }}</span></a-menu-item
						>
						<a-sub-menu v-if="item.children.length > 0" :key="index">
							<template #icon>
								<!-- <component v-if="item.icon" v-bind:is="item.icon"></component> -->
							</template>
							<template #title>{{ item.name }}</template>
							<a-menu-item v-for="subMenu in item.children" :key="subMenu.view"> {{ subMenu.name }}</a-menu-item>
						</a-sub-menu>
					</template>
				</a-menu>
			</a-layout-sider>
			<a-layout class="content">
				<a-layout-header><Header /></a-layout-header>
				<a-layout-content>
					<div class="main">
						<router-view v-slot="{ Component }">
							<keep-alive :exclude="excludeList">
								<component :is="Component" />
							</keep-alive>
						</router-view>
						<div id="subapp-viewport" />
					</div>
				</a-layout-content>
			</a-layout>
		</a-layout>
	</div>
</template>

<script>
import { onMounted, onUnmounted, reactive, toRefs } from "vue"
import Header from "@/components/Header.vue"
// import Footer from "@/components/Footer.vue";
import { useRouter } from "vue-router"
import { localGet } from "@/common/utils"
import { useStore } from "vuex"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"
export default {
	name: "Home",
	components: {
		Header,
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const state = reactive({
			userName: store.getters["user/userName"],
			userId: store.getters["user/userId"],
			merchantId: store.getters["user/merchantId"],
			defaultOpen: [3],
			currentPath: "/application",
			count: {
				number: 1,
			},
			openKeys: ["2", "3"],
			selectedKeys: [""],
			menuList: [],
			excludeList: [],
		})
		const unwatch = router.beforeEach((to, from, next) => {
			if (to.matched.length === 0) {
				// 如果未匹配到路由

				next("/Page404")
			}
			if (to.path == "/login") {
				// 如果路径是 /login 则正常执行
				next()
			} else {
				// 如果不是 /login，判断是否有 token
				if (!localGet("token")) {
					// 如果没有，则跳至登录页面
					next({ path: "/login" })
				} else {
					// 否则继续执行
					next()
				}
			}

			state.selectedKeys = [to.path.split("/")[1]]
		})
		const toPage = (value) => {
			if (value.key.indexOf("workorder") > -1) {
				router.push({ path: "/workorder_web/" + value.key })
			} else {
				router.push({ name: value.key })
			}
		}
		const toHome = () => {
			// router.push({ name: "loginApp" });
		}
		const getMenuList = () => {
			axios
				.post(api.menuList, {
					userId: state.userId,
					merchantId: state.merchantId,
				})
				.then((res) => {
					state.menuList = res
					if (res?.length === 0) {
						message.warning("暂无菜单，请联系管理员")
						router.push("setting")
					}
				})
		}
		const getAppType = () => {
			axios.post(api.appCategoryList, {}).then((res) => {
				localStorage.setItem("appTypeList", JSON.stringify(res))
			})
		}
		onMounted(() => {
			state.selectedKeys = [location.pathname.split("/")[1]]
			getMenuList()
			getAppType()
		})
		onUnmounted(() => {
			unwatch()
			store.dispatch("user/SET_WEBSITE_VALUE", "")
		})
		return {
			...toRefs(state),
			toPage,
			toHome,
			getMenuList,
		}
	},
}
</script>

<style scoped lang="scss">
.layout {
	min-height: 100vh;
	background-color: #ffffff;
}
.container {
	height: 100vh;
	width: 100%;
}
.aside {
	width: 200px !important;
	background-color: #000c17;
	overflow: hidden;
	overflow-y: auto;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}
.aside::-webkit-scrollbar {
	display: none;
}
.head {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
}
.head :hover {
	cursor: pointer;
}
.head > div {
	display: flex;
	align-items: center;
}

.head img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}
.head span {
	font-size: 20px;
	color: #ffffff;
}
.line {
	border-top: 1px solid hsla(0, 0%, 100%, 0.05);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.content {
	display: flex;
	flex-direction: column;
	max-height: 100vh;
	overflow: hidden;
}
.main {
	height: calc(100vh - 70px);
	overflow: auto;
	padding: 10px;
}
.ant-layout-header {
	background-color: white;
	padding: 0;
}
</style>
